.employer-product-log-dialog .mdc-dialog__surface {
  background-color: white !important;
  border-radius: 4px;
}

.employer-product-log-dialog .mdc-dialog__title {
  color: var(--mdc-theme-on-surface, #000);
}

.employer-product-log-dialog .mdc-dialog__content {
  padding: 20px;
}

.employer-product-log-dialog .mdc-dialog__actions {
  justify-content: flex-end;
  padding: 16px;
}
